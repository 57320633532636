<template>
  <div class="authincation h-100">
    <div class="container h-100">
      <div class="row justify-content-center h-100 align-items-center">
        <div class="col-md-6">
          <div class="authincation-content">
            <div class="row no-gutters">
              <div class="col-xl-12">
                <div class="auth-form">
                  <h4 class="text-center mb-4">Entrar</h4>
                  <div class="mb-3">
                    <label class="mb-1">
                      <strong>Login</strong>
                    </label>
                    <input type="text" class="form-control" placeholder="username" v-model="email">
                  </div>
                  <div class="mb-3">
                    <label class="mb-1">
                      <strong>Senha</strong>
                    </label>
                    <input type="password" class="form-control" v-model="password">
                  </div>
                  <div class="text-center mt-4">
                    <button type="submit" class="btn btn-primary btn-block" @click="login">Entrar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapActions, mapState } from 'vuex';

  export default {
    data(){
      return {
        email: null,
        password: null
      }
    },
    computed: {
      ...mapState(['auth'])
    },
    methods: {
      login(){
        this.api.login({
          login: this.email,
          password: this.password,
          onSuccess: (response) =>{
            this.setAuthToken(response.data.token);
            this.$router.push({name: 'cartNew'});
          }
        })
      },
      ...mapActions(['setAuthToken'])
    }
  }
</script>