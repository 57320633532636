// routes.js

import { globalStore } from './store';

import { createRouter, createWebHashHistory } from 'vue-router';

import ProductsIndexPage from './components/pages/products/IndexPage';
import CartNewPage from './components/pages/cart/NewPage';
import OrdersIndexPage from './components/pages/orders/IndexPage';
import LoginPage from './components/pages/session/LoginPage';

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  { path: '/', component: CartNewPage, name: 'root' },
  { path: '/login', component: LoginPage, name: 'login' },
  { path: '/cart/new', component: CartNewPage, name: 'cartNew' },
  { path: '/products', component: ProductsIndexPage, name: 'productsIndex' },
  { path: '/orders', component: OrdersIndexPage, name: 'ordersIndex' },
]

// var isAuthenticated = ;

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})

router.beforeEach(async (to, from, next) =>{
  if(!globalStore.getters.isAuthenticated && to.name != 'login'){
    next({name: 'login'});
  } else next();
});

export default router;