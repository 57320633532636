import { createStore } from "vuex";
import VuexPersistence from 'vuex-persist';
import { ToastService } from "./toastService";

export const MutationType = {
    ADD_CART_ITEM: 'ADD_CART_ITEM',
    CHANGE_CART_ITEM_AMOUNT: 'CHANGE_CART_ITEM_AMOUNT',
    REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
    CLEAR_CART: 'CLEAR_CART',
    SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
    UPDATE_ORDER_TOTALS: 'UPDATE_ORDER_TOTALS'
};

const vuexLocal = new VuexPersistence({
    key: 'caixa-facil-app',
    storage: window.sessionStorage,
  });

export const globalStore = createStore({
    plugins: [vuexLocal.plugin],
    state: {
        auth: {
            token: null
        },
        cartItems: [],
        totals: {
            pix: 0,
            cash: 0,
            credit_card: 0,
            debit_card: 0,
            total: 0
        }
    },
    getters: {
        cartTotal: state =>{
            return state.cartItems.reduce((accum,item) => accum + (item.price * item.amount), 0)
        },
        cartCount: state =>{
            return state.cartItems.length
        },
        isAuthenticated: state => {
            return state.auth.token != null;
        }
    },
    mutations: {
        [MutationType.SET_AUTH_TOKEN] (state, token){
            state.auth.token = token;
        },
        [MutationType.ADD_CART_ITEM] (state, {cartItem=null}){
            if(cartItem.sale_type == 'unitary'){
                cartItem.amount = 1;
            } else {
                cartItem.amount = 0;
            }
            state.cartItems = [...state.cartItems, cartItem];
        },
        [MutationType.REMOVE_CART_ITEM] (state, index){
            state.cartItems.splice(index, 1);
        },
        [MutationType.CHANGE_CART_ITEM_AMOUNT] (state, payload){
            state.cartItems[payload.index].amount = payload.amount;
            state.cartItems = [...state.cartItems];
        },
        [MutationType.CLEAR_CART](state){
            state.cartItems = []
        },
        [MutationType.UPDATE_ORDER_TOTALS](state, payload){
            console.log(state.totals);
            console.log(payload)
            state.totals = {...payload};
            console.log(state.totals);
        }
    },
    actions: {
        setAuthToken({commit}, token){
            commit(MutationType.SET_AUTH_TOKEN, token)
            ToastService.success('Login realizado com sucesso!');
        },
        addCartItem({commit}, cartItem){
            commit(MutationType.ADD_CART_ITEM, {cartItem: cartItem});
            ToastService.success('Item Adicionado');
        },
        changeCartItemAmount({commit},  payload){
            commit(MutationType.CHANGE_CART_ITEM_AMOUNT, payload);
        },
        removeCartItem({commit}, index){
            commit(MutationType.REMOVE_CART_ITEM, index);
            ToastService.info('Item removido');
        },
        clearCart({commit}){
            commit(MutationType.CLEAR_CART);
        },
        updateOrderTotals({commit}, payload){
            commit(MutationType.UPDATE_ORDER_TOTALS, payload)
        }
    }
})