<template>
  <BasePage>
    <div class="col-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h4 class="card-title">Vendas</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-responsive-md">
              <thead>
                <tr>
                  <th style="width:80px;">
                    <strong>#</strong>
                  </th>
                  <th>
                    <strong>Valor</strong>
                  </th>
                  <th>
                    <strong>Forma de Pagamento</strong>
                  </th>
                  <th>
                    <strong>Qtd. Itens</strong>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(order, index) in orders" :key="index">
                  <th scope="row">{{order.id}}</th>
                  <td>R$ {{order.total_value}}</td>
                  <td>Pix</td>
                  <td>5</td>
                  <td>
                    <div class="d-flex">
                      <a href="#" class="btn btn-primary shadow btn-xs sharp me-1"><i class="fa fa-eye"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </BasePage>
</template>

<script>

  import BasePage from '../partials/BasePage';

  export default {
    components: {
      BasePage,
    },
    data(){
      return {
        orders: []
      }
    },
    beforeMount(){
      this.api.getOrders({onSuccess: (response)=>{
        this.orders = response.data;
      }});
    }
  }
</script>