<template>
<div class="modal fade centered" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" :ref="modalId">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <h5 class="modal-title">Finalizar venda</h5>
      </div>
      <div class="modal-body">
        <table class="table">
          <thead>
            <tr>
              <th> Forma de pagamento</th>
              <th> Valor</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(payment, index) in this.payments" :key="index">
              <td> 
                <select class="form-control" v-model="payment.type" required>
                  <option value="cash" :selected="true">Dinheiro</option>
                  <option value="pix">Pix</option>
                  <option value="credit_card">Cartão de Crédito</option>
                  <option value="debit_card">Cartão de Débito</option>
                </select>
              </td>
              <td><money class="form-control" v-model.number="payment.value"  v-bind="this.moneyConfig"/></td>
              <td>
                <button v-if="index != 0" class="btn btn-outline-danger btn-sm" @click="this.removePayment(index)"><i class="fa fa-trash"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-info" @click="this.addPayment"><span class="btn-icon-start text-info" ><i class="fa fa-plus"></i></span>Pagamento</button>
          <span  class="text-danger" v-if="!this.isPaymentsEqualCartTotal"> Valor a ser pago diferente valor total. Diferença: {{ this.currencyFormat(this.paymentsTotal - this.cartTotal) }} </span>
          <h5>Total Pago: {{  this.currencyFormat(this.paymentsTotal)}}</h5>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-success" @click="saveOrder" :disabled="this.savingOrder">Salvar</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import { Money3Component } from 'v-money3'
  export default {
    components: {
      Money: Money3Component
    },
    data(){
      return {
        payments: [
          {type: 'cash', value: 0}
        ],
        paymentType: 'cash',
        discount: 0,
        savingOrder: false
      }
    },
    computed: {
      modalId(){
        return 'finishCartModal';
      },
      paymentsTotal(){
        return this.payments.reduce((accum,item) => accum + (item.value), 0)        
      },
      isPaymentsEqualCartTotal(){
        return this.paymentsTotal == this.cartTotal;
      },
      moneyConfig(){
        return {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2, 
          disableNegative: true
        }
      },
      ...mapState(['cartItems']),
      ...mapGetters(['cartTotal'])
    },
    methods: {
      addPayment(){
        this.payments.push({type: 'cash', value: 0})
      },
      removePayment(index){
        this.payments.splice(index, 1);
      },
      saveOrder(){
        this.savingOrder = true;
        this.api.createOrder({
          items: this.cartItems,
          // discount: this.discount,
          payments: this.payments,
          totalValue: this.paymentValue(),
          onSuccess: ()=>{
            var self = this.$refs[this.modalId];
            self.classList.remove("show")
            this.clearCart();
            alert('Venda Registrada!');
            window.location.reload();
          }
        })
      },
      paymentValue(){
        return this.cartTotal - ((this.discount/100.0) * this.cartTotal);
      },
      ...mapActions(['clearCart'])
    },
    watch: {
      cartTotal(newValue){
        var _payments = [...this.payments];
        _payments[0].value = newValue;
        this.payments = _payments;
      }
    }
  }
</script>