import { createApp } from 'vue';
import MainApp from './App.vue';


// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/stylesheets/application/style.css';
import './assets/stylesheets/application/main.css';

import 'vue-toast-notification/dist/theme-bootstrap.css';


import { Api } from './api';
import { globalStore } from './store';
import router from './routes';


const vueApp = createApp(MainApp);

vueApp.config.globalProperties.api = Api;
vueApp.config.globalProperties.currencyFormat = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}).format;
// Load third party apps

// vueApp.use(router);
vueApp.use(globalStore);
vueApp.use(router);
vueApp.directive('focus', {
  mounted(el) { // When the bound element is inserted into the DOM...
    el.focus() // Focus the element
  }
})

vueApp.mount('[vue-app]');
