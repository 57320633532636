<template>
  <div class="card cart-card">
    <div class="card-header py-4">
      <div class="row w-100">
        <div class="col-6">
          <div class="input-group">
            <input type="text" class="form-control"  v-model="searchCode" placeholder="Código do produto" v-focus v-on:keyup.enter="this.searchProductByCode">
          </div>
        </div>
        <div class="col">
          <div class="d-flex justify-content-end gap-5">
            <button class="btn btn-info" data-bs-toggle="modal" data-bs-target="#searchProductModal"><span class="btn-icon-start text-info" ><i class="fa fa-search"></i></span>Pesquisar produto</button>
            <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#ordersTodayModal"><span class="btn-icon-start text-success" ><i class="fa fa-inbox"></i></span>Vendas do dia</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <table class="table">
        <thead>
          <tr class="d-flex">
            <th class="col-1">#</th>
            <th class="col">Produto</th>
            <th class="col-1">Quantidade</th>
            <th class="col-1">Preço</th>
            <th class="col-1"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cartItem, index) in cartItems" :key="index" class="d-flex">
            <td class="col-1">{{ index + 1}}</td>
            <td class="col">
              {{ cartItem.name }}
              <br>
              <span v-if="cartItem.sale_type == 'unitary'" class="text-muted">Val. Unit: {{ this.currencyFormat(cartItem.price) }}</span>
              <span v-else class="text-muted">Val. Kg: {{ this.currencyFormat(cartItem.price) }}</span>
            </td>
            <td class="col-1"><input type="number" v-model="cartItem.amount" class="form-control" min="1" @change="changeCartItemAmount({'index': index, 'amount': $event.target.value})"></td>
            <td class="col-1"><h6>{{ this.currencyFormat(cartItem.price * cartItem.amount) }}</h6></td>
            <td class="col-1"><button class="btn btn-outline-primary btn-xs" type="button"  @click="removeCartItem(index)"><i class="fa fa-trash"></i></button></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer py-4 d-flex flex-column justify-content-start">
      <div class="d-flex justify-content-between">
        <h4>Qtd. de Itens: {{ cartCount }}</h4>
        <h4>Total: {{ this.currencyFormat(cartTotal) }}</h4>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-danger" @click="clearCart" :disabled="finishButtonsDisabled"><span class="btn-icon-start text-danger" ><i class="fa fa-trash"></i></span>Cancelar</button>
        <button class="btn btn-success" :disabled="finishButtonsDisabled"  data-bs-toggle="modal" data-bs-target="#finishCartModal"><span class="btn-icon-start text-success" ><i class="fa fa-shopping-cart"></i></span>Finalizar</button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex'

  export default {
    data(){
      return {
        isSearching: false,
        searchCode: '',
        searchTimer: undefined
      }
    },
    computed: {
      finishButtonsDisabled(){
        return this.cartCount == 0;
      },
      ...mapGetters(['cartTotal', 'cartCount']),
      ...mapState(['cartItems'])
    },
    methods: {
      searchProductByCode(){
        if(this.searchCode != ''){
          // clearTimeout(this.timer)
          // this.timer = setTimeout(() => {
          this.api.getProduct({code: this.searchCode, onSuccess:(response)=>{
            this.products = response.data
            this.isSearching = false
            this.searchCode = '';
            if(response.data.length > 0){
              var cartItem = response.data[0];
              this.addCartItem(cartItem);
            }
          }})
          // }, 300)
        }
      },
      ...mapActions(['addCartItem', 'removeCartItem', 'clearCart', 'changeCartItemAmount'])
    },
    // watch: {
    //   searchCode(){
    //     this.searchProductByCode();
    //   }
    // }
  }

</script>

<style scoped="true" lang="scss">
  .cart-card{
    height: 87vh;
    .card-body{
      overflow-y: scroll;
    }
  }
</style>