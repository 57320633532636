<template>
<div class="modal fade centered" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" :ref="modalId">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="max-width: 90vw;">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <h5 class="modal-title">Pesquisr Produto</h5>
      </div>
      <div class="modal-body">
        <ProductsList />
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import ProductsList from './_ProductsList.vue';
  export default {
    components: {
      ProductsList
    },
    computed: {
      modalId(){
        return 'searchProductModal';
      },
    },
  }
</script>