<template>
  <div class="">
    <!-- <div class="row">
      <Navbar />
    </div> -->
    <div class="row">
      <div class="col-12">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>

// import Navbar from './components/layout/NavBar'

export default {
  name: 'App',
  components: {
    // Navbar,
  }
}
</script>

<style>

</style>
