<template>
<div class="modal fade centered" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" :ref="modalId">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <h5 class="modal-title">Cadastrar Produto</h5>
      </div>
      <div class="modal-body">
        <div class="basic-form">
          <div class="mb-3 row">
            <label class="col-sm-3 col-form-label">Nome</label>
            <div class="col">
              <input type="text" class="form-control" v-model="name">
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-3 col-form-label">Código</label>
            <div class="col">
              <input type="text" class="form-control" v-model="code">
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-3 col-form-label">Preço:</label>
            <div class="col">
              <input type="number" class="form-control" v-model="price">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-success" @click="saveProduct">Salvar</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    data(){
      return {
        name: null,
        code: null,
        price: null
      }
    },
    computed: {
      modalId(){
        return 'newProductModal';
      }
    },
    methods: {
      saveProduct(){
        this.api.createProduct({
          name: this.name,
          code: this.code,
          price: this.price,
          onSuccess: ()=>{
            window.location.reload();
          }
        })
      }
    }
  }
</script>