<template>
  <div class="row">
    <div class="col-12">
      <div class="input-group">
        <input type="text" class="form-control" @input="onProductSearch" placeholder="Pesquise pelo nome ou código do produto">
        <button class="btn btn-primary" type="button"><i class="fa fa-search"></i></button>
      </div>
    </div>
  </div>
  <div class="products-list container-fluid">
    <div class="row ">
      <div v-for="(product, index) in products" :key="index" class="card col-xl-2 col-lg-3 col-sm-4 product-card m-2 " @click="addCartItem(product)">
        <div class="card-body d-flex justify-content-center align-items-center flex-column">
          <span class="title h4">{{ product.name }}</span>
          <span class="value text-primary h3">{{ this.currencyFormat(product.price) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  export default {
    data(){
      return {
        products: []
      }
    },
    beforeMount(){
      this.api.getProducts({onSuccess: (response)=>{
        this.products = response.data;
      }});
    },
    methods: {
      onProductSearch(event){
        var term = event.target.value;
        this.api.getProduct({term: term, onSuccess:(response)=>{
          this.products = response.data
        }})
      },
      ...mapActions(['addCartItem'])
    }
  }

</script>

<style scoped="true" lang="scss">
  .products-list{
    height: 50vh;
    overflow-y: scroll;
    .product-card{
      .title{
        font-size: 1.2em;
      }
    }
  }
</style>