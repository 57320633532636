<template>
  <BasePage>
    <div class="col-12">
     <div class="card">
       <div class="card-header d-flex justify-content-between align-items-center">
         <h4 class="card-title">Produtos</h4>
         <button type="button" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#newProductModal">Novo Produto</button>
       </div>
       <div class="card-body">
         <div class="table-responsive">
           <table class="table table-responsive-md">
             <thead>
               <tr>
                 <th style="width:80px;">
                   <strong>Código</strong>
                 </th>
                 <th>
                   <strong>Nome</strong>
                 </th>
                 <th>
                   <strong>Preço</strong>
                 </th>
                 <th></th>
               </tr>
             </thead>
             <tbody>
               <tr v-for="(product, index) in products" :key="index">
                 <td>{{product.code}}</td>
                 <td>{{product.name}}</td>
                 <td>R$ {{product.price}}</td>
                 <td>
                   <div class="d-flex">
                     <a href="#" class="btn btn-primary shadow btn-xs sharp me-1"><i class="fa fa-eye"></i></a>
                     <a href="#" class="btn btn-primary shadow btn-xs sharp me-1"><i class="fa fa-pencil"></i></a>
                   </div>
                 </td>
               </tr>
             </tbody>
           </table>
         </div>
       </div>
     </div>
   </div>
   <NewProductModal />
  </BasePage>
</template>

<script>
  import NewProductModal from './_NewProductModal';
  import BasePage from '../partials/BasePage.vue';
  export default {
    components: {
      NewProductModal,
      BasePage
    },
    data(){
      return {
        products: []
      }
    },
    beforeMount(){
      this.api.getProducts({onSuccess: (response)=>{
        this.products = response.data;
      }});
    }
  }
</script>