import axios from 'axios';
import { globalStore } from './store';

class ApiClass{
  hostUrl = 'https://www.caixa-facil.com/api'; // PRODUCTION
  // hostUrl = 'http://localhost:3000/api'; // DEVELOPMENT

  createProduct({onSuccess=null, onError=null, name=null, code=null, price=null}){
    var data = {
      product: {
        name: name,
        code: code,
        price: price
      }
    }
    this._post({
      url: '/products',
      data: data,
      onSuccess: onSuccess,
      onError: onError
    });
  }
 getProducts({onSuccess=null, onError=null}){
    this._get({
      url: '/products',
      onSuccess: onSuccess,
      onError: onError
    })
  }
  getProduct({onSuccess=null, onError=null, term=null, code=null}){
    var params = []
    if(term)
      params.push(`term=${term}`);
    if(code)
      params.push(`code=${code}`);
    if(params.length > 0){
      params = `?${params.join('&')}`
    } else {
      params = ''
    }
    this._get({
      url: `/products${params}`,
      onSuccess: onSuccess,
      onError: onError
    });
  }
  createOrder({onSuccess=null, onError=null, items=[], discount=null, payments=null}){
    var data = {
      order: {
        order_items_attributes: items.map((e)=>{ return {product_id: e.id, amount: e.amount}}),
        discount: discount,
        payments_attributes: payments.map((e)=>{ return {payment_type: e.type, value: e.value}})
      },
    }
    this._post({
      url: '/orders',
      data: data,
      onSuccess: onSuccess,
      onError: onError
    })
  }
  getOrders({onSuccess=null, onError=null}){
    this._get({
      url: '/orders',
      onSuccess: onSuccess,
      onError: onError
    })
  }
  getORder({onSuccess=null, onError=null, orderId=null}){
    this._get({
      url: `/order/${orderId}`,
      onSuccess: onSuccess,
      onError: onError
    })
  }
  getOrdersTotal({onSuccess=null, onError=null}){
    this._get({
      url: '/orders/totals',
      onSuccess: onSuccess,
      onError: onError
    })
  }
  login({onSuccess=null, onError=null, login=null, password=null}){
    axios.post(`${this.hostUrl}/auth/login`,{
      email: login,
      password: password
    }, {headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}}).then(onSuccess).catch(onError);
  }
  _get({url=null, onSuccess=null, onError=null}){
    axios.get(`${this.hostUrl}${url}`, {headers: {
      'Access-Control-Allow-Origin': '*', 
      'Authorization': globalStore.state.auth.token
      // any other headers
    }}).then(onSuccess).catch(onError)
  }
  _post({url=null, onSuccess=null, onError=null, data=null}){
    axios.post(`${this.hostUrl}${url}`,data, {headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': globalStore.state.auth.token}}).then(onSuccess).catch(onError)
  }
}

export const Api = new ApiClass();