<template>
  <div class="modal fade centered" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" :ref="modalId">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <h5 class="modal-title">Extrato do Dia</h5>
        </div>
        <div class="modal-body">
          <table class="table">
            <thead>
              <tr>
                <th> Forma de Pagamento</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pix</td>
                <td>{{  this.currencyFormat(totals.pix)}}</td>
              </tr>
              <tr>
                <td>Dinheiro</td>
                <td>{{ this.currencyFormat(totals.cash) }}</td>
              </tr>
              <tr>
                <td>Cartão de Crédito</td>
                <td>{{ this.currencyFormat(totals.credit_card) }}</td>
              </tr>
              <tr>
                <td>Cartão de Débito</td>
                <td>{{ this.currencyFormat(totals.debit_card) }} </td>
              </tr>
              <tr>
                <td>Total</td>
                <td>{{ this.currencyFormat(totals.total) }} </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
    import { mapState } from 'vuex'
    export default {
      computed: {
        modalId(){
          return 'ordersTodayModal';
        },
        ...mapState(['totals'])
      },
    }
  </script>