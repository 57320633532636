<template>
  <BasePage>
    <div class="row">
      <div class="col-12">
        <CartItemList />
      </div>
      <OrdersTodayModal />
      <ProductSearchModal />
      <FinishCartModal />
    </div>
  </BasePage>
</template>

<script>
  import CartItemList from './_CartItemList';
  import ProductSearchModal from './_ProductSearchModal';
  import OrdersTodayModal from '../orders/_OrdersTodayModal.vue';
  import FinishCartModal from './_FinishCartModal';
  import BasePage from '../partials/BasePage';
  import { mapActions } from 'vuex'

  export default {
    components: {
      CartItemList,
      OrdersTodayModal,
      ProductSearchModal,
      FinishCartModal,
      BasePage
    },
    mounted(){
      this.api.getOrdersTotal({onSuccess:(response)=>{
        this.updateOrderTotals(response.data);
      }})
    },
    methods: {
      ...mapActions(['updateOrderTotals'])
    }
  }
</script>