import { useToast} from "vue-toast-notification";

const toast = useToast();

const timeout = 2000;
const position = 'bottom-right'

class ToastServiceClass{
  success(message){
    this._toast(message, {});
  }

  info(message){
    this._toast(message, {type: 'info'});
  }

  error(message){
    this._toast(message, {type: 'error'});
  }

  _toast(message, {type='success'}){
    toast.open({
      message: message,
      type: type,
      position: position,
      duration: timeout,
    });
  }
}


export const ToastService = new ToastServiceClass();